import React from "react"
import Hero from "../../components/hero"
import SiteMetaData from "../../components/sitemeta"
import teamStyles from "../../components/teams.module.scss"
import TeamData from "../../components/team-data.js"
import Layout from "../../components/layout-teams"

const KidsTeam = ({data}) => (
  <Layout data={data}>
    <div style={({"backgroundColor":"black"})}>
      <SiteMetaData 
        title="Kids Teams | RF Dance"
        description="OC's Homegrown latin dance classes with Rhythmic Fusion. $15 for a single class, or $65 for a 5 class bundle." 
        pathname="teams/kids"/>
      <Hero 
        titleRed="RF"
        titleWhite="Kids" />

      <div className={`${teamStyles.auditions} content-block`}>
        <div className={`${teamStyles.container} container`}>
          <TeamData
              name="RF Kids Auditions"
              subheading="All levels"
              link="https://www.eventbrite.com/e/91948766235"
              date="March 22, 2020">
            <p>Our RF Kids enjoy instruction in footwork, partner-work, and styling. This is a well-rounded, all-levels team for our youngest dancers.</p>
          </TeamData>
        </div>
      </div>
    </div>
  </Layout>
)

export default KidsTeam